# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/


window.mes_app = window.mes_app or {}
((schedules, $) ->

  schedules.day_resize_on_remove = ->
    if($(window).width() >= 1440)
      $('tr').not(':first').each ->
        h1=0
        day=$(@).first().find('.schedule_day').attr('id')
        $(@).find('.schedule_day').each ->
          h1=Math.max(h1,parseFloat($(@).css('height')))
        h2=parseFloat($("##{day}.backup-box > ul").css('height'))

        if(h1>h2)
          $(@).css('height',h1+'px')
          $("##{day}.backup-box").css('min-height',$(@).height()+'px')
        else
          $("##{day}.backup-box").css('min-height',h2+'px')
          $(@).css('height',$("##{day}.backup-box").height()+'px')

  schedules.day_resize = ->
    if($(window).width() >= 1440)
      $('tr').not(':first').each ->
        h1=parseFloat($(@).first().css('height'))
        day=$(@).first().find('.schedule_day').attr('id')
        off_top=$(@).first().offset().top

        if day
          h2=parseFloat($("##{day}.backup-box").css('height'))

          $(@).first().css('height',Math.max(h1,h2)+'px')
          $("##{day}.backup-box").css('min-height',Math.max(h1,h2)+'px')
          $("##{day}.backup-box").offset({top:off_top})

      $($(".backup-box.empty.top").get().reverse()).each ->
        $(@).offset({top:$(@).next().offset().top - $(@).height()})
      $(".backup-box.empty.bottom").each ->
        $(@).offset({top:$(@).prev().offset().top+$(@).prev().height()})

  schedules.set_datepicker = ->
    if $(window).width() >= 1024
      today = new Date()
      last_week = today.getDate() - 7
      today.setDate(last_week)
      $(".start_date").datepicker
        dateFormat: 'yy-mm-dd'
        firstDay: 1
        beforeShowDay: (date) ->
          day = date.getDay()
          if day is 1 and date >= today
            [true, ""]
          else
            [false, ""]
    else
      $(".start_date").prop 'type', 'date'

  schedules.cancel = ->
    $(document).off "click", "#cancel"
    $(document).on "click", "#cancel", ->
      $(".guardian").removeClass("remove")
      $(".guardian").removeClass("switch")
      $(".guardian").removeClass("toSwitch")

      $(".day").removeClass("add")
      $(".day").removeClass("selected_day")
      $(".backup-box").removeClass("add")
      $(".backup-box").removeClass("selected_day")
      $(".select").removeClass("selected")
      $(".select").removeClass("selected-backup")
      $(".selector").hide()
      $(".instructions").hide()
      $("#cancel").hide()

      $(".guardian").css('color','inherit')
      $(".guardian").css('backgroundColor','')
      $(".day").css('borderBottom','')
      $(".backup-box").css('borderBottom','')

  schedules.remove_guardian = ->
    $(document).off "click", "#remove"
    $(document).on "click", "#remove", ->
      $("#cancel").trigger('click')

      $(".guardian").addClass("remove")

      $(".instructions").text("Please select a guardian to remove").show()
      $("#cancel").show()

    $(document).on "mouseover", ".guardian.remove",->
      $(@).css('backgroundColor','#d90d07')
      $(@).css('color','white')
    $(document).on "mouseleave", ".guardian.remove",->
      $(@).css('backgroundColor','transparent')
      $(@).css('color','inherit')

    $(document).off "click",".guardian.remove"
    $(document).on "click",".guardian.remove",->

      if $(@).prev().is('br')
        $(@).prev().remove()
      url= "/guardian_for_days/#{$(@).data('gid')}"
      $.ajax
        url: url
        dataType: "script"
        type: "DELETE"

  schedules.switch_guardians = ->
    clicks=0
    active_item=null
    $(document).off "click", "#switch"
    $(document).on "click", "#switch", ->
      clicks=0
      active_item=null
      $("#cancel").trigger('click')
      $(".guardian").addClass("switch")

      $(".instructions").text("Please select the first guardian to switch").show()

      $("#cancel").show()

    $(document). on "mouseover", ".guardian.switch", ->
      $(@).css('backgroundColor', 'orange')
    $(document).on "mouseleave", ".guardian.switch", ->
      $(@).css('backgroundColor', 'transparent')
      if active_item != null
        active_item.css('backgroundColor','orange')

    $(document). off "click", ".guardian.switch"
    $(document). on "click", ".guardian.switch",->
      if clicks==0
        $(@).addClass("toSwitch")
        $(".guardian.switch.toSwitch").css('backgroundColor','orange')
        $(".instructions").text("Please select the second guardian to switch")
        active_item=$(@)
        clicks+=1

        if active_item.is('.BackUp')
          day=$(@).parent().parent().attr('id')
          $('tr').not(':first').each ->
            first_day=$(@).first().find('.schedule_day').attr('id')
            if day != first_day
              $(@).find('.schedule_day').each ->
                $(@).find('.guardian').each ->
                  $(@).removeClass("switch")
        else
          tr=$(@).parent().parent().parent()
          day=tr.first().find('.schedule_day').attr('id')
          $('.backup-box').each ->
            if $(@).attr('id') != day
              $(@).find('.guardian').each ->
                $(@).removeClass("switch")
      else
        gId2=$(".guardian.switch.toSwitch").data('gid')
        $(".guardian").removeClass("toSwitch")

        $(".instructions").text("Please select the first guardian to switch")

        clicks=0
        active_item=null
        url="/guardian_for_days/#{$(@).data('gid')}"
        $.ajax
          url: url
          dataType: "script"
          type: "PUT"
          data:
            oId:gId2
          success:
            $(".guardian").css('backgroundColor','transparent')

  schedules.add_guardian = ->
    schedule_day=0
    $(document). off "click", "#add"
    $(document). on "click", "#add", ->
      $("#cancel").trigger('click')

      $(".day").addClass("add")
      $(".backup-box").addClass("add")

      $(".instructions").text("Please select a day").show()
      $("#cancel").show()

    $(document). on "mouseover", ".day.add, .backup-box.add", ->
      $(@).css('borderBottom','3px solid green')
    $(document). on "mouseleave", ".day.add, .backup-box.add", ->
      $(@).css('borderBottom','')


    $(document). off "click", ".day.add"
    $(document). on "click", ".day.add", ->
      $(@).addClass("selected_day")

      schedule_day=$(".day.add.selected_day > .schedule_day").data("dayid")
      $("##{schedule_day}.selector").show()


      $(".day").removeClass("add")
      $(".backup-box").removeClass("add")
      $(@).removeClass("selected_day")

      $("select").addClass("selected")

      $(".instructions").text("Please select a guardian to add.")
      url="/schedules/load_guardians"
      $.ajax
        url: url
        dataType: "script"
        type: "GET"
        data:
          id: schedule_day

    $(document).off "click",".backup-box.add"
    $(document).on "click",".backup-box.add",->
      $(@).addClass("selected_day")

      schedule_day=$(".backup-box.add.selected_day").data("dayid")
      $("##{schedule_day}-backup.selector").show()

      $(".day").removeClass("add")
      $(".backup-box").removeClass("add")

      $(@).removeClass("selected_day")

      $("select").addClass("selected-backup")

      $(".instructions").text("Please select a guardian to add.")
      url="/schedules/load_guardians"
      $.ajax
        url: url
        dataType: "script"
        type: "GET"
        data:
          id: schedule_day

    $(document). off "change", ".selected"
    $(document). on "change", ".selected", ->
      $("select").removeClass("selected")

      $(".day").addClass("add")
      $(".backup-box").addClass("add")
      $(".instructions").text("Please select a day")

      url="/guardian_for_days"
      $.ajax
        url: url
        dataType: "script"
        type: "POST"
        data:
          day: schedule_day
          guardian: $(@).val()
          role: "Fixer"
        success:
          $(".day").css('backgroundColor','')

    $(document). off "change", ".selected-backup"
    $(document). on "change", ".selected-backup", ->
      $("select").removeClass("selected-backup")

      $(".day").addClass("add")
      $(".backup-box").addClass("add")
      $(".instructions").text("Please select a day")

      url="/guardian_for_days"
      $.ajax
        url: url
        dataType: "script"
        type: "POST"
        data:
          day: schedule_day
          guardian: $(@).val()
          role: "BackUp"
        success:
          $(".backup-box").css('borderBottom','')

  schedules.change_role = ->
    $(document). on 'mouseover',".clickable", ->
      $(@).css('color','black')
      $(@).css('cursor','pointer')
    $(document). on 'mouseleave',".clickable", ->
      $(@).css('color','')
      $(@).css('cursor','')

    $(document). off 'click',".clickable"
    $(document). on 'click', ".clickable", ->
      url="/guardian_for_days/#{$(@).data('gid')}/update_role"
      $.ajax
        url: url
        dataType: "script"
        type: "PATCH"
        success:
            $(@).css('backgroundColor','')

  schedules.showSchedule = (id) ->
    x = document.getElementById(id + "Collapse")
    if x.style.display is "none"
      x.style.display = "block"
      document.cookie = "project_#{id}_status=block"
    else
      x.style.display = "none"
      document.cookie = "project_#{id}_status=none"

  schedules.getCookie = (name) ->
    value = "; #{document.cookie}"
    parts = value.split("; #{name}=")
    if parts.length is 2
      parts.pop().split(';').shift()

  schedules.applyScheduleStatusFromCookies = () ->
    document.querySelectorAll(".card-title.link").forEach (element) =>
      projectId = element.getAttribute("onclick").match(/\d+/)[0]
      status = schedules.getCookie("project_#{projectId}_status")
      if status
        x = document.getElementById("#{projectId}Collapse")
        x.style.display = status

  schedules.load_more_button = ->
    $(document).on 'click', '.load-more-button', ->
    loadMoreButtons = document.querySelectorAll('.load-more-button')

    loadMoreButtons.forEach (button) ->
      clickCount = 0

      button.addEventListener 'click', ->
        clickCount++
        projectId = button.getAttribute('data-project-id')
        schedulesContainer = $("[data-project-id='#{projectId}']")

        $.ajax
          url: "/schedules/load_more_schedules?project_id=#{projectId}"
          type: 'GET'
          success: (data) ->
            schedulesContainer.append(data)

            if clickCount is 1
              button.style.display = 'none'
          error: (error) ->
            console.error(error)


) window.mes_app.schedules = window.mes_app.schedules or {}, jQuery
